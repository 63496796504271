import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    bulkDeleteStopDrafts,
    createStopDrafts,
    getStopDrafts,
} from "../../api/stop-draft";
import { archiveStopDraftTour, getActiveTours } from "../../api/tours";
import { ReduxState } from "../../shared/types/redux";
import { generateDemoStopDrafts } from "../../shared/utility/stop-draft";
import { fakeFromAddresses, fakeToAddresses } from "../../shared/values/lists";
import useContacts from "../data/useContacts";
import { INTE_BARA_POSTEN_DEMO_DATA } from "../../shared/data/demo-data";
import { CreateStopDraftData } from "../../shared/types/api";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import { getRandomNumber } from "../../shared/utility/misc";

function useDemoMutations({ onSuccess }: { onSuccess?: () => void }) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);

    const { contacts } = useContacts();

    const {
        mutate: createInteBaraPostenDemoData,
        isPending: isCreatingInteBaraPostenDemoData,
    } = useMutation({
        mutationFn: async () => {
            const promises = [];

            const testList = INTE_BARA_POSTEN_DEMO_DATA.sort((a, b) =>
                a.from.toLowerCase().localeCompare(b.from.toLowerCase())
            ).slice(0, 150);

            for (let i = 0; i < testList.length; i++) {
                const data = testList[i];

                const orderNumber = getRandomNumber(1000000).toString();

                const pickup: CreateStopDraftData = {
                    to_location: data.from,
                    stop_type_id: STOP_DRAFT_TYPE.Pickup,
                    tags: data.tag ? [data.tag] : undefined,
                    time_tooltip: data.pickupTime,
                    phone: "",
                    cargo_content: "",
                    estimated_offload_time: 15,
                    order_number: orderNumber,
                };

                const dropoff: CreateStopDraftData = {
                    to_location: data.to,
                    stop_type_id: STOP_DRAFT_TYPE.Dropoff,
                    tags: data.tag ? [data.tag] : undefined,
                    time_tooltip: data.dropoffTime,
                    phone: "",
                    cargo_content: "",
                    estimated_offload_time: 15,
                    order_number: orderNumber,
                };

                promises.push(createStopDrafts([pickup, dropoff]));

                if (i % 2 === 0) {
                    await new Promise((resolve) => setTimeout(resolve, 1500));
                }
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.demoStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const {
        mutate: createDemoStopDrafts,
        isPending: isCreatingDemoStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
                customersIds: contacts?.map((contact) => contact.id),
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.demoStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const {
        mutate: createDemoBigVolumeStopDrafts,
        isPending: isCreatingDemoBigVolumeStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
                bigVolume: true,
                noDate: true,
                customersIds: contacts?.map((contact) => contact.id),
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.demoStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const {
        mutate: createTestStopDrafts,
        isPending: isCreatingTestStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
                from: fakeFromAddresses,
                to: fakeToAddresses,
                noDate: true,
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.testStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: deleteAllStopDrafts, isPending: isDeletingAllStopDrafts } =
        useMutation({
            mutationFn: async () => {
                const {
                    data: { stop_drafts: stopDrafts },
                } = await getStopDrafts();

                await bulkDeleteStopDrafts(stopDrafts.map((sd) => sd.id));
            },

            onSuccess: () => {
                toast.success(t("successMessage.allStopDraftsDeleted"));
                onSuccess?.();
            },

            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    const {
        mutate: archiveAllActiveTours,
        isPending: isArchivingAllActiveTours,
    } = useMutation({
        mutationFn: async () => {
            const { data: tours } = await getActiveTours();
            for (let i = 0; i < tours.length; i++) {
                const tour = tours[i];
                try {
                    await archiveStopDraftTour(tour.tour_id);
                } catch (error) {
                    console.error(error);
                }
            }
        },

        onSuccess: () => {
            toast.success(t("successMessage.allActiveToursArchived"));
            onSuccess?.();
        },

        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    return {
        createDemoStopDrafts,
        isCreatingDemoStopDrafts,
        deleteAllStopDrafts,
        createTestStopDrafts,
        isCreatingTestStopDrafts,
        isDeletingAllStopDrafts,
        archiveAllActiveTours,
        isArchivingAllActiveTours,
        createDemoBigVolumeStopDrafts,
        isCreatingDemoBigVolumeStopDrafts,
        createInteBaraPostenDemoData,
        isCreatingInteBaraPostenDemoData,
    };
}

export default useDemoMutations;
