import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "../Tooltip";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./style.scss";

type Props = {
    label: string;
    color?: string;
    isCompleted?: boolean;
    hideTooltip?: boolean;
    groupedAmount?: number;
    tooltipData?: {
        description: string;
        description2: string;
        icon: IconDefinition;
        iconColor: string;
    };
};

function StopCardIcon(props: Props) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            style={{ position: "relative" }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <AnimatePresence>
                {showTooltip && props.tooltipData && !props.hideTooltip && (
                    <Tooltip>
                        <div className="stop-card-tooltip">
                            <p className="text-sm">
                                {props.tooltipData.description}
                            </p>
                            <div className="status">
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    size="xs"
                                    color={props.tooltipData.iconColor}
                                />

                                <p className="text-xs">
                                    {props.tooltipData.description2}
                                </p>
                            </div>
                        </div>
                    </Tooltip>
                )}
            </AnimatePresence>
            <motion.div
                whileHover={props.hideTooltip ? {} : { scale: 1.2 }}
                className="stop-card-icon"
                style={
                    props.color
                        ? { backgroundColor: props.color }
                        : { backgroundColor: "var(--color-neutral-100)" }
                }
            >
                {!!props.groupedAmount && (
                    <div className="grouped-amount">
                        <span className="text-2xs">{props.groupedAmount}</span>
                    </div>
                )}
                {!!props.tooltipData && !props.hideTooltip && (
                    <div className="status">
                        <FontAwesomeIcon
                            icon={props.tooltipData.icon}
                            size="xs"
                            color={props.tooltipData.iconColor}
                        />
                    </div>
                )}
                {props.isCompleted ? (
                    <FontAwesomeIcon
                        icon={faCheck}
                        color="var(--color-pure-white)"
                    />
                ) : (
                    <p className="text-xs">{props.label}</p>
                )}
            </motion.div>
        </div>
    );
}

export default StopCardIcon;
