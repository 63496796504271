import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getDirectionsRoute } from "../../api/google";
import usePolylinePath from "./usePolylinePath";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

function useDirections({
    hide = false,
    path,
    directionsColor,
    zIndex,
}: {
    hide?: boolean;
    path: google.maps.LatLngLiteral[];
    directionsColor?: string;
    zIndex?: number;
}) {
    const geometryLibrary = useMapsLibrary("geometry");

    const { data: directionsResponse } = useQuery({
        queryKey: ["directions", path] as const,
        queryFn: async ({ queryKey: [, path] }) => {
            if (path.length > 25) return null;
            const res = await getDirectionsRoute(path);
            return res.data;
        },
        enabled: !hide,
    });

    const polylinePath = useMemo(() => {
        if (!directionsResponse || !geometryLibrary) return [];

        const route = directionsResponse.routes[0];
        if (!route) return [];

        const decodedPath = geometryLibrary.encoding.decodePath(
            route.polyline.encodedPolyline
        );

        return decodedPath.map(({ lat, lng }) => ({
            lat: lat(),
            lng: lng(),
        }));
    }, [directionsResponse, geometryLibrary]);

    usePolylinePath({
        hide,
        zIndex,
        polylineColor: directionsColor,
        path: polylinePath || [],
    });

    const pathLegsDurationInMinutes = useMemo(() => {
        if (!directionsResponse) return [];

        return directionsResponse.routes[0].legs.map((leg) => {
            let legDurationInSeconds = Number(leg.duration.replace("s", ""));
            if (isNaN(legDurationInSeconds)) {
                legDurationInSeconds = 60;
            }
            return legDurationInSeconds / 60;
        });
    }, [directionsResponse]);

    const pathDurationInMinutes = useMemo(() => {
        return pathLegsDurationInMinutes.reduce((acc, leg) => acc + leg, 0);
    }, [pathLegsDurationInMinutes]);

    return {
        pathDurationInMinutes,
        pathLegsDurationInMinutes,
    };
}

export default useDirections;
