import { useMemo } from "react";
import IconOLD from "../../components/UI/IconOLD";
import useDefaultMapCenter from "../../hooks/functionality/useDefaultMapCenter";
import usePanToMapCenter from "../../hooks/functionality/usePanToMapCenter";
import { debounce } from "../../shared/utility/misc";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import Map from "../Map";
import "./style.scss";

type Props = {
    center?: google.maps.LatLng;
    onCenterChanged?: (center: google.maps.LatLngLiteral) => void;
};

function PinMap(props: Props) {
    const { onCenterChanged } = props;

    usePanToMapCenter(props.center);
    const defaultMapCenter = useDefaultMapCenter();

    const mapCenterChangedHandler = useMemo(
        () =>
            debounce((center: google.maps.LatLngLiteral) => {
                onCenterChanged?.(center);
            }, 200),

        [onCenterChanged]
    );

    return (
        <div className="pin-map">
            <Map
                mapId={GOOGLE_MAP_IDS.PinMap}
                defaultCenter={defaultMapCenter}
                defaultZoom={12}
                onCenterChanged={(e) => {
                    mapCenterChangedHandler(e.detail.center);
                }}
                showTrafficToggle
            >
                <div className="pin">
                    <IconOLD type="map-pin" fontSize="35px" />
                </div>
            </Map>
        </div>
    );
}

export default PinMap;
