import { faHourglassEnd } from "@fortawesome/pro-duotone-svg-icons";
import { faShare, faTag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../components/inputs/Checkbox";
import Badge from "../../../components/UI/Badge";
import Table from "../../../containers/Table";
import { StopSort } from "../../../hooks/functionality/useStopAndTourFilterAndSort";
import { StopTableData } from "../../../pages/BigVolume";
import { StopDraft } from "../../../shared/types/api";
import { convertToNumberWithSpaces } from "../../../shared/utility/misc";
import { getStopLocationText } from "../../../shared/utility/stop-draft";
import "./style.scss";

type Props = {
    data: StopTableData[];
    onRowClick: (item: StopTableData) => void;

    //Mark all props
    isMarkingAll: boolean;
    onMarkingAllChange: (value: boolean) => void;
    maxMarkAllStops: number;

    //Selected stops
    selectedStops: StopDraft[];
    onSelectedStopsChange: (
        value: boolean,
        stops: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }
    ) => void;

    //sorting
    activeSort: StopSort;
    onSortChange: (sort: StopSort) => void;

    sectionsSize: "quarter" | "half" | "full";
};

function StopTable(props: Props) {
    const { t } = useTranslation();

    const hourGlassIconStyle = {
        "--fa-primary-color": "var(--color-neutral-900)",
        "--fa-primary-opacity": "1",
        "--fa-secondary-color": "var(--color-neutral-400)",
        "--fa-secondary-opacity": "1",
        padding: "12px 8px",
    };

    return (
        <Table
            data={props.data}
            emptyStateLabel={t("bigVolume.emptyUnhandledStopsLabel")}
            onRowClick={props.onRowClick}
            maxHeight={
                props.sectionsSize === "quarter"
                    ? "var(--section-height)"
                    : "var(--section-height-full)"
            }
            columns={[
                {
                    key: "id",
                    width: "50px",
                    title: (
                        <Checkbox
                            disabled={props.data.length > props.maxMarkAllStops}
                            checked={props.isMarkingAll}
                            onChange={props.onMarkingAllChange}
                            style={{
                                padding: "6px",
                            }}
                        />
                    ),
                    render: ({ stops }) => {
                        if (
                            stops?.pickup.origin_location &&
                            !stops.pickup.move_accepted_at
                        ) {
                            return (
                                <FontAwesomeIcon
                                    icon={faHourglassEnd}
                                    size="lg"
                                    style={
                                        hourGlassIconStyle as React.CSSProperties
                                    }
                                    fixedWidth
                                    title={t("bigVolume.requestedStopHover", {
                                        orginLocation:
                                            stops.pickup.origin_location.name,
                                    })}
                                />
                            );
                        }

                        return (
                            <Checkbox
                                checked={props.selectedStops.some(
                                    (s) => s.id === stops?.pickup.id
                                )}
                                onChange={(newChecked) =>
                                    stops
                                        ? props.onSelectedStopsChange(
                                              newChecked,
                                              stops
                                          )
                                        : () => {}
                                }
                                disabled={!stops}
                                style={{
                                    padding: "12px 6px",
                                }}
                            />
                        );
                    },
                },
                {
                    key: "to_location",
                    width: "170px",
                    title: t("bigVolume.loading"),
                    getValue: ({ stops, orderDraft }) =>
                        stops
                            ? getStopLocationText(stops.pickup)
                            : orderDraft?.from_location || "-",
                    getHoverTitle: ({ stops, orderDraft }) =>
                        stops?.pickup.to_location ||
                        orderDraft?.from_location ||
                        "",
                    getTextColor: ({ stops, orderDraft }) =>
                        !stops && !orderDraft?.from_location
                            ? "var(--color-red-600)"
                            : undefined,
                    sortMode:
                        props.activeSort === "pickup-asc"
                            ? "asc"
                            : props.activeSort === "pickup-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "pickup-asc"
                                ? "pickup-desc"
                                : "pickup-asc"
                        ),
                },
                {
                    key: "to_location",
                    width: "170px",
                    title: t("bigVolume.unloading"),
                    getValue: ({ stops, orderDraft }) =>
                        stops
                            ? getStopLocationText(stops.dropoff)
                            : orderDraft?.to_location || "-",
                    getHoverTitle: ({ stops, orderDraft }) =>
                        stops?.dropoff.to_location ||
                        orderDraft?.to_location ||
                        "",
                    getTextColor: ({ stops, orderDraft }) =>
                        !stops && !orderDraft?.to_location
                            ? "var(--color-red-600)"
                            : undefined,
                    sortMode:
                        props.activeSort === "dropoff-asc"
                            ? "asc"
                            : props.activeSort === "dropoff-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "dropoff-asc"
                                ? "dropoff-desc"
                                : "dropoff-asc"
                        ),
                },
                {
                    key: "contact",
                    width: "130px",
                    title: t("bigVolume.contact"),
                    getValue: ({ stops, orderDraft }) =>
                        stops?.dropoff.contact?.name ||
                        stops?.pickup.contact?.name ||
                        orderDraft?.dropoff_contact?.name ||
                        orderDraft?.pickup_contact?.name ||
                        "-",
                    sortMode:
                        props.activeSort === "contact-asc"
                            ? "asc"
                            : props.activeSort === "contact-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "contact-desc"
                                ? "contact-asc"
                                : "contact-desc"
                        ),
                },
                {
                    key: "weight_kg",
                    width: "100px",
                    title: t("bigVolume.weight"),
                    getValue: ({ stops, orderDraft }) =>
                        stops?.pickup.weight_kg
                            ? convertToNumberWithSpaces(
                                  stops.pickup.weight_kg,
                                  "kg"
                              )
                            : orderDraft?.weight_kg
                            ? convertToNumberWithSpaces(
                                  orderDraft.weight_kg,
                                  "kg"
                              )
                            : "-",
                    getTextColor: ({ stops, orderDraft }) =>
                        !stops && !orderDraft?.weight_kg
                            ? "var(--color-red-600)"
                            : undefined,
                    sortMode:
                        props.activeSort === "weight-asc"
                            ? "asc"
                            : props.activeSort === "weight-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "weight-desc"
                                ? "weight-asc"
                                : "weight-desc"
                        ),
                },

                {
                    key: "dropoff_time",
                    width: "80px",
                    title: t("bigVolume.dropoffTime"),
                    getValue: ({ stops }) =>
                        stops?.dropoff.time_tooltip
                            ? stops?.dropoff.time_tooltip
                            : "-",
                    sortMode:
                        props.activeSort === "dropoff-time-asc"
                            ? "asc"
                            : props.activeSort === "dropoff-time-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "dropoff-time-desc"
                                ? "dropoff-time-asc"
                                : "dropoff-time-desc"
                        ),
                },
                {
                    key: "tag",
                    width: "100%",
                    title: t("bigVolume.tags"),
                    render: ({ stops, orderDraft }) =>
                        stops?.pickup.tags || orderDraft?.tags ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "4px",
                                    padding: "4px 0",
                                }}
                            >
                                {stops?.pickup.order?.assigned_location_id && (
                                    <Badge
                                        title={t("bigVolume.fodOrder")}
                                        variant="success"
                                        icon={faShare}
                                    />
                                )}
                                {stops?.pickup.origin_location?.name && (
                                    <Badge
                                        title={t(
                                            "popup.stopsInfo.assignedFrom",
                                            {
                                                orginLocation:
                                                    stops?.pickup
                                                        .origin_location?.name,
                                            }
                                        )}
                                        variant="success"
                                        icon={faShare}
                                    />
                                )}
                                {(
                                    stops?.pickup.tags ||
                                    orderDraft?.tags ||
                                    []
                                ).map((tag) => (
                                    <Badge
                                        key={tag}
                                        title={tag}
                                        variant="neutral"
                                        icon={faTag}
                                    />
                                ))}
                            </div>
                        ) : (
                            <p>-</p>
                        ),
                    sortMode:
                        props.activeSort === "tag-asc"
                            ? "asc"
                            : props.activeSort === "tag-desc"
                            ? "desc"
                            : null,
                    onSort: () =>
                        props.onSortChange(
                            props.activeSort === "tag-desc"
                                ? "tag-asc"
                                : "tag-desc"
                        ),
                },
            ]}
        />
    );
}

export default StopTable;
