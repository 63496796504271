import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setExternalCarrierForOrder } from "../../api/order";
import {
    createStopDrafts,
    setStopDraftsAsExternal,
} from "../../api/stop-draft";
import IconOLD from "../../components/UI/IconOLD";
import Button from "../../components/buttons/Button";
import useValidateAddress from "../../hooks/data/useValidateAddress";
import PointsMap from "../../maps/PointsMap";
import NewOrderPopup from "../../popups/NewOrderPopup";
import { CreateStopDraftData } from "../../shared/types/api";
import { MapPoint } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { dateToString } from "../../shared/utility/date";
import { getRandomNumber, onFormError } from "../../shared/utility/misc";
import {
    SCHENKER_CARRIER_NAME,
    STOP_DRAFT_TYPE,
} from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import ConfirmManualOrder from "./ConfirmManualOrder";
import CargoInputForm from "./forms/CargoInputForm";
import DeliveryInputForm from "./forms/DeliveryInputForm";
import TransportInputForm from "./forms/TransportInputForm";
import {
    CargoForm,
    cargoSchema,
    DeliveryForm,
    deliverySchema,
    TransportForm,
    transportSchema,
} from "./schemas";
import "./style.scss";
import useExternalCarriers from "../../hooks/data/useExternalCarriers";

type Props = {};

type StepKey = "delivery" | "cargo" | "transport" | "confirm";

function FleetManualOrder(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const { externalCarriers } = useExternalCarriers();

    const [activeStep, setActiveStep] = useState<StepKey>("delivery");

    const [isNewOrderPopupOpen, setIsNewOrderPopupOpen] = useState(false);

    const {
        handleSubmit: handleDeliverySubmit,
        control: deliveryControl,
        getValues: getDeliveryValues,
        watch: watchDelivery,
        reset: resetDeliveryInputs,
        formState: { isValid: isDeliveryValid },
    } = useForm<DeliveryForm>({
        resolver: zodResolver(deliverySchema),
        defaultValues: {
            orderNumber: `${user?.location_entity?.name} - ${getRandomNumber(
                1000000
            )}`,
            fromAddress: "",
            toAddress: "",
            date: undefined,
            email: "",
            dropoffPhone: "",
        },
    });

    const {
        handleSubmit: handleCargoSubmit,
        control: cargoControl,
        getValues: getCargoValues,
        watch: watchCargo,
        reset: resetCargoInputs,
        formState: { isValid: isCargoValid },
    } = useForm<CargoForm>({
        resolver: zodResolver(cargoSchema),
        defaultValues: {
            weight: 0,
            weightOfHeaviestPackage: 0,
            numberOfEuPallets: 0,
            dangerousGoods: false,
            warmTransport: false,
            stackable: false,
            dimensions: [
                {
                    length: "",
                    width: "",
                    height: "",
                },
            ],
            cargoContent: "",
            driverInstructions: "",
        },
    });

    const {
        handleSubmit: handleTransportSubmit,
        control: transportControl,
        getValues: getTransportValues,
        reset: resetTransportInputs,
        watch: watchTransport,
        formState: { isValid: isTransportValid },
    } = useForm<TransportForm>({
        resolver: zodResolver(transportSchema),
        defaultValues: {
            transportOption: undefined,
        },
    });

    const resetInputs = useCallback(() => {
        resetDeliveryInputs();
        resetCargoInputs();
        resetTransportInputs();
    }, [resetCargoInputs, resetDeliveryInputs, resetTransportInputs]);

    const chosenFromAddress = watchDelivery("fromAddress");
    const chosenToAddress = watchDelivery("toAddress");

    const chosenTransportOption = watchTransport("transportOption");

    const chosenDangerousGoods = watchCargo("dangerousGoods");

    const { data: fromAddressData } = useValidateAddress(chosenFromAddress);
    const { data: toAddressData } = useValidateAddress(chosenToAddress);

    const getCargoContent = useCallback(
        (data: DeliveryForm & CargoForm & TransportForm) => {
            let cargoContent = "";

            cargoContent = `${cargoContent}${t(
                "fleetManualOrder.confirm.email"
            )}: ${data.email}\n`;
            cargoContent = `${cargoContent}${t(
                "fleetManualOrder.confirm.dropoffPhone"
            )}: ${data.dropoffPhone}\n\n`;

            if (data.weightOfHeaviestPackage) {
                cargoContent = `${cargoContent}${t(
                    "fleetManualOrder.confirm.weightOfHeaviestPackage"
                )}: ${data.weightOfHeaviestPackage} kg\n`;
            }

            if (data.numberOfEuPallets) {
                cargoContent = `${cargoContent}${t(
                    "fleetManualOrder.confirm.numberOfEuPallets"
                )}: ${data.numberOfEuPallets}\n\n`;
            }

            for (let i = 0; i < data.dimensions.length; i++) {
                const { length, width, height } = data.dimensions[i];
                cargoContent = `${cargoContent}${t(
                    "fleetManualOrder.confirm.length"
                )}: ${length}, ${t(
                    "fleetManualOrder.confirm.width"
                )}: ${width}, ${t(
                    "fleetManualOrder.confirm.height"
                )}: ${height}\n`;
            }

            cargoContent = `\n\n${cargoContent}${t(
                "fleetManualOrder.confirm.dangerousGoods"
            )}: ${data.dangerousGoods ? t("general.yes") : t("general.no")}\n`;

            cargoContent = `${cargoContent}${t(
                "fleetManualOrder.confirm.warmTransport"
            )}: ${data.warmTransport ? t("general.yes") : t("general.no")}\n`;

            cargoContent = `${cargoContent}${t(
                "fleetManualOrder.confirm.stackable"
            )}: ${data.stackable ? t("general.yes") : t("general.no")}\n\n`;

            cargoContent = `${cargoContent}${t(
                "fleetManualOrder.confirm.cargoContent"
            )}:\n${data.cargoContent}`;

            return cargoContent;
        },
        [t]
    );

    const { mutate: createManualOrder, isPending: isCreatingManualOrder } =
        useMutation({
            mutationFn: async (
                data: DeliveryForm & CargoForm & TransportForm
            ) => {
                const cargoContent = getCargoContent(data);

                const stopData: CreateStopDraftData[] = [
                    {
                        stop_type_id: STOP_DRAFT_TYPE.Pickup,
                        to_location: data.fromAddress,
                        phone: "",
                        cargo_content: cargoContent,
                        estimated_offload_time: 15,
                        order_number: data.orderNumber,
                        weight_kg: data.weight,
                        driver_instructions: data.driverInstructions,
                        date_tooltip: dateToString(data.date),
                    },
                    {
                        stop_type_id: STOP_DRAFT_TYPE.Dropoff,
                        to_location: data.toAddress,
                        phone: data.dropoffPhone,
                        cargo_content: data.orderNumber,
                        estimated_offload_time: 15,
                        order_number: data.orderNumber,
                        weight_kg: data.weight,
                        driver_instructions: data.driverInstructions,
                        date_tooltip: dateToString(data.date),
                    },
                ];

                if (data.transportOption === "schenker") {
                    const schenkerCarrier = externalCarriers?.find(
                        (carrier) => carrier.name === SCHENKER_CARRIER_NAME
                    );
                    if (!schenkerCarrier) {
                        throw new Error("schenker carrier not found");
                    }

                    const res = await createStopDrafts(stopData);

                    const res2 = await setStopDraftsAsExternal({
                        pickup: res.data[0],
                        dropoff: res.data[1],
                        date: dateToString(data.date),
                        time: "06:00",
                    });

                    await setExternalCarrierForOrder({
                        orderId: res2.data.id,
                        externalCarrierId: schenkerCarrier.id,
                        reference: "",
                        date: dateToString(data.date),
                        time: "06:00",
                    });
                    return;
                } else if (data.transportOption === "ordinary") {
                    await createStopDrafts(stopData);
                    return;
                }
            },
            onSuccess: () => {
                resetInputs();
                setActiveStep("delivery");
                toast.success(t("successMessage.manualOrderCreated"));
            },
            onError: (error) => {
                if (error.message === "schenker carrier not found") {
                    toast.error(t("errorMessage.schenkerCarrierNotFound"));
                    return;
                }
                toast.error(t("errorMessage.unknown"));
            },
        });

    const steps: {
        key: StepKey;
        label: string;
    }[] = useMemo(() => {
        return [
            { key: "delivery", label: t("fleetManualOrder.deliveryTitle") },
            {
                key: "cargo",
                label: t("fleetManualOrder.cargoTitle"),
            },
            {
                key: "transport",
                label: t("fleetManualOrder.transportTitle"),
            },
            {
                key: "confirm",
                label: t("fleetManualOrder.confirmTitle"),
            },
        ];
    }, [t]);

    const forwardHandler = useMemo(() => {
        switch (activeStep) {
            case "delivery":
                return handleDeliverySubmit(
                    () => setActiveStep("cargo"),
                    onFormError
                );
            case "cargo":
                return handleCargoSubmit(
                    () => setActiveStep("transport"),
                    onFormError
                );
            case "transport":
                if (chosenTransportOption === "alrik") {
                    return () => {
                        setIsNewOrderPopupOpen(true);
                    };
                }
                return handleTransportSubmit(
                    () => setActiveStep("confirm"),
                    onFormError
                );
            default:
                break;
        }
    }, [
        activeStep,
        chosenTransportOption,
        handleCargoSubmit,
        handleDeliverySubmit,
        handleTransportSubmit,
    ]);

    const backwardHandler = useCallback(() => {
        switch (activeStep) {
            case "cargo":
                setActiveStep("delivery");
                break;
            case "transport":
                setActiveStep("cargo");
                break;
            case "confirm":
                setActiveStep("transport");
                break;
            default:
                break;
        }
    }, [activeStep]);

    const isStepCompleted = useCallback(
        (step: StepKey) => {
            switch (step) {
                case "delivery":
                    return isDeliveryValid;
                case "cargo":
                    return isCargoValid;
                case "transport":
                    return isTransportValid;
                case "confirm":
                    return false;
                default:
                    return false;
            }
        },
        [isCargoValid, isDeliveryValid, isTransportValid]
    );

    const mapPoints = useMemo(() => {
        if (!fromAddressData || !toAddressData) return [];

        const points: MapPoint[] = [];

        if (fromAddressData.lat && fromAddressData.lng) {
            points.push({
                id: "from",
                location: {
                    lat: +fromAddressData.lat,
                    lng: +fromAddressData.lng,
                },
                infoWindow: {
                    title: fromAddressData.formatted_address,
                },
                label: "1",
            });
        }

        if (toAddressData.lat && toAddressData.lng) {
            points.push({
                id: "to",
                location: {
                    lat: +toAddressData.lat,
                    lng: +toAddressData.lng,
                },
                infoWindow: {
                    title: toAddressData.formatted_address,
                },
                label: "2",
            });
        }

        return points;
    }, [fromAddressData, toAddressData]);

    return (
        <div className="fleet-manual-order">
            <div className="top">
                <p className="text-sm">{t("fleetManualOrder.title")}</p>
                <p
                    style={{
                        color: "var(--text-color-light)",
                    }}
                >
                    {t("fleetManualOrder.description")}
                </p>
            </div>

            <div className="steps">
                {steps.map((step) => (
                    <section
                        key={step.key}
                        style={{
                            color:
                                activeStep === step.key
                                    ? "var(--text-color)"
                                    : isStepCompleted(step.key)
                                    ? "var(--text-color-alt)"
                                    : "var(--text-color-light)",
                        }}
                        onClick={() => setActiveStep(step.key)}
                    >
                        <IconOLD
                            type={
                                isStepCompleted(step.key) &&
                                activeStep !== step.key
                                    ? "filled-circle-check"
                                    : "circle-check"
                            }
                            style={{
                                color:
                                    activeStep === step.key
                                        ? "var(--text-color)"
                                        : isStepCompleted(step.key)
                                        ? "var(--text-color-alt)"
                                        : "var(--text-color-light)",
                            }}
                        />
                        <p>{step.label}</p>
                    </section>
                ))}
            </div>

            <div className="content">
                {activeStep === "delivery" ? (
                    <DeliveryInputForm control={deliveryControl} />
                ) : activeStep === "cargo" ? (
                    <CargoInputForm control={cargoControl} />
                ) : activeStep === "transport" ? (
                    <TransportInputForm control={transportControl} />
                ) : activeStep === "confirm" ? (
                    <ConfirmManualOrder
                        data={{
                            ...getDeliveryValues(),
                            ...getCargoValues(),
                            ...getTransportValues(),
                        }}
                    />
                ) : null}

                <div className="aside">
                    <PointsMap
                        mapId={GOOGLE_MAP_IDS.ManualOrderMap}
                        showDirections
                        points={mapPoints}
                    />
                </div>
            </div>

            <div className="actions">
                <Button
                    label={t("general.goBack")}
                    variant={"secondary"}
                    style={{ width: "150px" }}
                    onClick={backwardHandler}
                />
                <Button
                    label={
                        activeStep === "confirm"
                            ? t("fleetManualOrder.book")
                            : t("fleetManualOrder.nextStep")
                    }
                    variant={"primary"}
                    style={{ width: "150px" }}
                    onClick={
                        activeStep !== "confirm"
                            ? forwardHandler
                            : () =>
                                  createManualOrder({
                                      ...getDeliveryValues(),
                                      ...getCargoValues(),
                                      ...getTransportValues(),
                                  })
                    }
                    isLoading={isCreatingManualOrder}
                    disabled={
                        activeStep === "cargo" ||
                        activeStep === "transport" ||
                        activeStep === "confirm"
                            ? !!chosenDangerousGoods
                            : false
                    }
                />
            </div>

            <NewOrderPopup
                showPopup={isNewOrderPopupOpen}
                onClose={() => setIsNewOrderPopupOpen(false)}
                onOrderCreated={() => {
                    setIsNewOrderPopupOpen(false);
                    resetInputs();
                    setActiveStep("delivery");
                    toast.success(t("successMessage.manualOrderCreated"));
                }}
                isFromFleetPlanner
                manualOrderData={{
                    ...getDeliveryValues(),
                    ...getCargoValues(),
                    ...getTransportValues(),
                }}
            />
        </div>
    );
}

export default FleetManualOrder;
