import axios from "axios";
import environment from "../environment";
import {
    DirectionsResponse,
    GoogleAutoCompleteResponse,
    GoogleGeocodeResponse,
} from "../shared/types/google";
import { getLocale } from "../shared/utility/misc";

export async function getLocationFromAddress(address: string) {
    const result = await axios.get<GoogleGeocodeResponse>(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
            params: {
                address: address,
                key: environment.googlePlaceApiKey,
                language: getLocale() || "en",
            },
        }
    );

    const firstResult = result.data.results?.[0];
    if (!firstResult) {
        return {
            location: null,
            locationType: null,
        };
    }

    return {
        location: firstResult.geometry.location,
        locationType: firstResult.geometry.location_type,
    };
}

export async function getAddressFromLocation(
    location: google.maps.LatLngLiteral
) {
    const result = await axios.get<GoogleGeocodeResponse>(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
            params: {
                latlng: location.lat + "," + location.lng,
                key: environment.googlePlaceApiKey,
                language: getLocale() || "en",
            },
        }
    );

    const firstResult = result.data.results?.[0];
    if (!firstResult) {
        return {
            address: null,
            locationType: null,
        };
    }

    return {
        address: firstResult.formatted_address,
        locationType: firstResult.geometry.location_type,
    };
}

export async function getGooglePlacesAutoComplete(
    input: string,
    country?: string
) {
    return axios.post<{
        suggestions?: GoogleAutoCompleteResponse[];
    }>(
        "https://places.googleapis.com/v1/places:autocomplete",
        {
            input: input,
            includedRegionCodes: country || "se",
            languageCode: getLocale() || "en",
            includedPrimaryTypes: ["street_address", "establishment"],
        },
        {
            headers: {
                "X-Goog-Api-Key": environment.googlePlaceApiKey,
                "x-Goog-FieldMask":
                    "suggestions.placePrediction.placeId,suggestions.placePrediction.text.text",
            },
        }
    );
}

export async function getDirectionsRoute(
    path: {
        lat: number;
        lng: number;
    }[]
) {
    const originLocation = {
        latitude: path[0].lat,
        longitude: path[0].lng,
    };

    const destinationLocation = {
        latitude: path[path.length - 1].lat,
        longitude: path[path.length - 1].lng,
    };

    let intermediates;
    if (path.length > 2) {
        intermediates = path.slice(1, path.length - 1).map((point) => ({
            location: {
                latLng: {
                    latitude: point.lat,
                    longitude: point.lng,
                },
            },
            vehicleStopover: true,
        }));
    }

    return axios.post<DirectionsResponse>(
        "https://routes.googleapis.com/directions/v2:computeRoutes",
        {
            origin: {
                location: {
                    latLng: originLocation,
                },
                vehicleStopover: true,
            },
            destination: {
                location: {
                    latLng: destinationLocation,
                },
                vehicleStopover: true,
            },
            intermediates: intermediates,
            travelMode: "DRIVE",
            routingPreference: "TRAFFIC_UNAWARE",
            computeAlternativeRoutes: false,
            units: "METRIC",
            polylineEncoding: "ENCODED_POLYLINE",
        },

        {
            headers: {
                "X-Goog-Api-Key": environment.googlePlaceApiKey,
                "x-Goog-FieldMask": "routes.legs.duration,routes.polyline",
            },
        }
    );
}
